/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import RangeInput from 'components/range-input/range-input';
import Panel from 'components/panel/panel';
import useWindowSize from 'hooks/use-window-size';
import { getCauseList } from 'utils/api';
import 'react-table/react-table.css';
import './cause-table.scss';
import NoDataModal from 'components/no-data-modal/no-data-modal';

function CauseTable({
  accountUID,
  programUID,
  userLatitude,
  userLongitude,
  userZipCode,
  causeView,
  setSelectedCause,
}) {
  const { screenSize } = useWindowSize();
  const [isTableDataLoading, setTableDataIsLoading] = useState(true);
  const [causeList, setCauseList] = useState();
  const [numPages, setNumPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [sorted, setSorted] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [causeDistance, setCauseDistance] = useState(50);
  const isDistanceDisabled = !!searchTerm;
  const [isHidden, setIsHidden] = useState(true);
  const [doneTyping, setDoneTyping] = useState();

  const getColumns = () => {
    switch (screenSize) {
      case 'large':
        return [
          {
            Header: 'Name',
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
            maxWidth: 150,
          },
          {
            Header: 'Distance',
            accessor: 'CauseDistance',
            maxWidth: 50,
            Cell: (row) => (
              <div style={{ textAlign: 'right' }}>{row.value} miles</div>
            ),
          },
        ];
      case 'medium':
        return [
          {
            Header: 'School Name',
            id: 'CauseName',
            accessor: 'CauseName',
          },
          {
            Header: 'Location',
            accessor: 'CauseLocation',
          },
        ];
      default:
        return [
          {
            Header: 'School',
            id: 'CauseName',
            width: 320,
            accessor: (d) => [d.CauseName, d.CauseLocation, d.CauseDistance],
            Cell: (row) => (
              <div>
                <div>{row.value[0]}</div>
                <div>{`${row.value[1]} - ${row.value[2]} miles away`}</div>
              </div>
            ),
          },
        ];
    }
  };

  const pageSize = screenSize === 'small' ? 10 : 15;

  const handleTableCauseClick = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          setSelectedCause(rowInfo.original);
          // console.log('CAUSE CLICKED', rowInfo.original);
        },
        style: {
          cursor: 'pointer',
        },
      };
    }
    return {};
  };

  const getTableData = async () => {
    setTableDataIsLoading(true);
    let query = `select * from (select *,[dbo].[UDF_Distance](latitude,longitude,${userLatitude},${userLongitude}) as causedistance from ${causeView}) as a where 1=1`;
    if (searchTerm !== '') {
      query += ` and (causename like '%${searchTerm.replace(
        "'",
        "''"
      )}%' or causecity like '${searchTerm.replace("'", "''")}%')`;
    } else {
      query += ` and latitude not in (0,1) and causeDistance <= ${causeDistance}`;
    }

    const params = {
      userUID: '',
      accountUID,
      programUID,
      sqlStatement: query,
      lat: userLatitude,
      lng: userLongitude,
      distance: causeDistance,
      filter: '',
      pageNum,
      limit: pageSize,
      start: pageNum * pageSize,
    };

    if (sorted && sorted.length) {
      // console.log('sorted && sorted.length', sorted && sorted.length);
      const arr = [];
      arr.push({
        property: sorted[0].id,
        direction: sorted[0].desc ? 'DESC' : 'ASC',
      });
      //   console.log(arr, 'my sort');
      params.sort = `[{"property": "${
        sorted[0].id === 'CauseLocation' ? 'CauseCity' : sorted[0].id
      }", "direction": ${sorted[0].desc ? '"DESC"' : '"ASC"'}}]`;
    }

    const causeResp = await getCauseList(params);
    // console.log("CAUSE RESPONSE", causeResp)

    const causes = causeResp.causes.map((cause) => ({
      ...cause,
      CauseLocation: `${cause.CauseCity}, ${cause.CauseState}`,
      CauseDistance: cause.CauseDistance.toFixed(1),
    }));

    setCauseList(causes);
    setNumPages(Math.ceil(causeResp.totalCount / pageSize));
    if (causeResp.causes.length < 1 && doneTyping) {
      setIsHidden(false);
    }
    setTableDataIsLoading(false);
  };

  useEffect(() => {
    if (accountUID && userLatitude && userLongitude) {
      getTableData();
    }
  }, [
    accountUID,
    causeDistance,
    causeView,
    pageNum,
    doneTyping,
    pageSize,
    sorted,
    userLatitude,
    userLongitude,
  ]);

  const fetchData = (state) => {
    //  console.log('fetch data', state);
    setPageNum(state.page);
    setSorted(state.sorted);
  };

  const typingToSearch = (e) => {
    setIsHidden(true);
    setSearchTerm(e);
    setDoneTyping(false);
    setTimeout(() => {
      setDoneTyping(true);
    }, 1500);
  };

  return (
    <div>
      <Panel>
        <div className="table-controls">
          <div className="form-group">
            <label htmlFor="causeSearchInput">Search Schools</label>
            <input
              type="text"
              className="form-control"
              id="causeSearchInput"
              aria-describedby="causeSearch"
              placeholder="Enter School Name"
              value={searchTerm}
              onChange={(e) => typingToSearch(e.target.value)}
            />
          </div>
          <div className="form-group">
            <RangeInput
              label={
                isDistanceDisabled
                  ? `Any distance from ${userZipCode}`
                  : `Within ${causeDistance} miles of ${userZipCode}`
              }
              min={10}
              max={200}
              value={causeDistance}
              onChange={(newDistance) => setCauseDistance(newDistance)}
              step={10}
              disabled={isDistanceDisabled}
              editable={false}
              showValue={false}
              className="form-control"
            />
          </div>
        </div>
        <ReactTable
          columns={getColumns()}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={causeList}
          showPaginationTop
          showPaginationBottom={false}
          pages={numPages} // Display the total number of pages
          loading={isTableDataLoading} // Display the loading overlay when we need it
          onFetchData={fetchData}
          pageSize={pageSize}
          showPageSizeOptions={false}
          showPageJump={false}
          className="-striped -highlight"
          resizable={false}
          page={pageNum}
          getTrProps={handleTableCauseClick}
          useExpanded={false}
          defaultSorted={[
            {
              id: 'CauseDistance',
              desc: false,
            },
          ]}
        />
      </Panel>
      <NoDataModal
        hidden={isHidden}
        setIsHidden={setIsHidden}
        searchTerm={searchTerm}
      />
    </div>
  );
}

export default CauseTable;
