import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import qs from 'qs';

import useLocalStorage from 'hooks/use-local-storage';
import {
  getHHSUserFromAuthID,
  getHHSUserFromUUID,
  getHHSStats,
} from 'utils/api';
import { FullScreenLoader } from 'components/loader/loader';

const loadStates = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState();
  const [user, setUser] = useState({});
  const [userID, setUserID] = useLocalStorage('user_id', '');
  const [localSerial, setLocalSerial] = useLocalStorage('serial', '');
  const [serial, setSerial] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [urlParams, setUrlParams] = useState();
  const [inAppView, isInAppView] = useState(false);
  const [loadState, setLoadState] = useState(loadStates.LOADING);
  const [resetPassword, setResetPassword] = useState(false);

  const searchVals = qs.parse(window.location.search.slice(1));
  const userIdForLogin = searchVals.user_uuid || userID || '';
  const serialValue = searchVals.serial || serial || '';
  useEffect(() => {
    console.log(
      'in initial useeffect render',
      searchVals,
      window.location.search,
      searchVals.user_uuid
    );
    setUrlParams(searchVals);
    if (searchVals?.ref) {
      isInAppView(true);
    }
    if (searchVals?.loginType) {
      setLoggedIn(false);
      setUserID('');
      setLocalSerial('');
    } else {
       console.log('in initial useeffect render', searchVals);
      if (searchVals.user_uuid || user?.UUID) {
        setLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    //console.log('in initial useeffect render', searchVals);
    setUrlParams(searchVals);
    if (searchVals.user_uuid || user?.UUID) {
      setLoggedIn(true);
    }
  }, [user]);

  // see what the url is - if it includes login - then clear the localstoarage then sign in

  useEffect(() => {
    if (searchVals.user_uuid || userID) {
      getHHSUserFromUUID(userIdForLogin?.split('/')[0]).then((res) => {
        if (res.Success) {
          if (res.signedIn) {
            setUserID(res.UUID);
            setLocalSerial(serialValue);
            setSerial(serialValue);
            window.gtag('set', { userId: res.UUID });
            window.gtag('event', 'login', { method: 'userUuid' });
            setUser(res);
            setLoggedIn(true);
            console.log('in authcontext -SIgned in', res);
            //  window.location.href = `http://localhost:3000?user_uuid=${res.UUID}&ref=app/redeem`;
          } else {
            setUserID('');
            setUser({});
            setLoggedIn(false);
          }
        } else if (searchVals.loginType && !searchVals.user_uuid) {
          setUserID('');
          setUser({});
          setLoggedIn(false);
        }
      });
    }
  }, [userID, serial]);

  const updateUserStats = async () => {
    if (user?.UserID) {
      const UserStats = await getHHSStats({ UserID: user.UserID });
      setUser((prevUser) => ({
        ...prevUser,
        UserStats,
      }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        updateUserStats,
        setUser,
        inAppView,
        setUserID,
        resetPassword,
        setResetPassword,
        serial,
        localSerial,
        setLoggedIn,
        prevPage,
        setPrevPage,
        loadState,
        setLoadState,
      }}
    >
      {/* {isLoggedIn === undefined || loadState === loadStates.LOADING ? (
        <FullScreenLoader />
      ) : ( */}
      {children}
      {/* )} */}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const authValue = useContext(AuthContext);
  return authValue;
};

export { AuthContext as default, AuthProvider, useAuth };
