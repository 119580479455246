import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import ReactGA from 'react-ga';
import 'index.scss';
import { AuthProvider } from 'contexts/auth-context';
import { ThemeProvider } from 'contexts/theme-context';
import App from 'app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
