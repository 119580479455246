import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'contexts/auth-context';
import qs from 'qs';
import Page from 'components/page/page';
import {
  // attemptLogin,
  // attemptSignup,
  // attemptForgotPassword,
  attemptResetPassword,
} from 'utils/api';

import './auth-styles.scss';

function ResetPasswordPage() {
  const { user, setResetPassword, isInAppView } = useAuth();
  const [resetUUID, setResetUUID] = useState('');

  console.log('in reset password page');

  // const { loginWithRedirect } = useAuth0();

  // const location = useLocation();
  // const isRedeemPage = location?.pathname.toLowerCase().includes('redeem');

  useEffect(() => {
    const searchVals = qs.parse(window.location.search.slice(1));
    console.log('in login', searchVals, 'my search Vals');
    if (searchVals.reset) {
      setResetUUID(searchVals.reset);

      // setPrevPage('authLogin');
      // loginWithRedirect({
      //   prompt: 'login',
      //   screen_hint: searchVals.screen_hint ? searchVals.screen_hint : '',
      // });
    } else {
      console.log('in else in login');
      setTimeout(() => {
        if (!user) {
          window.location.href = 'https://hannafordhelpsschools.com';
        }
      }, 1500);
      //
    }
  }, []);

  const submitResetPassword = async (e) => {
    e.preventDefault();
    const password = document.getElementById('password').value;

    const passwordConf = document.getElementById('password-confirmation').value;
    console.log('password', password, passwordConf);
    const submitBtn = document.getElementById('btn-login');
    if (password !== passwordConf) {
      document.getElementById('error-message').className =
        'alert alert-danger show-error';
      document.getElementById('error-message').innerHTML =
        'Passwords do not match';
      return;
    }
    console.log('resetUUID', resetUUID);
    const resetRes = await attemptResetPassword(resetUUID, password);

    console.log('window', window, password);
    if (resetRes.Success) {
      document.getElementById('error-message').className =
        'alert alert-success show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${resetRes.Message}`;

      if (isInAppView || window?.screen.width < 600) {
        window.location.href = 'https://hannafordhelpsschools.com';
      } else {
        window.location.href = 'https://portal.hannafordhelpsschools.com/login';
      }
      // setUser(resetRes);
      // setUserID(resetRes.UUID);
      // setPrevPage('authLogin');
      // setLoggedIn(true);-
    } else {
      document.getElementById('error-message').className =
        'alert alert-danger show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${resetRes.Message}`;
    }

    // if (passwordConf.validity.typeMismatch) {
    //   console.log('ismatch', passwordConf.validity);
    // }

    // submit.addEventListener('click', () => {
    //   if (email.validity.typeMismatch) {
    //     email.setCustomValidity('Please enter correct email');
    //   } else {
    //     email.setCustomValidity('');
    //   }
    // });
  };

  return (
    <Page>
      <div className="login-container">
        <div className="col-sm-offset-4 login-box">
          <div className="login-header">
            <img src="header-logo1.png" alt="Hannaford" />
            <h3>Hannaford Helps Schools</h3>
          </div>

          <div className="reset-pw-form" id="loginButton">
            Reset Your Password
          </div>
          <hr />
          <div id="error-message" className="alert alert-danger hide-error" />
          <form onSubmit={submitResetPassword} id="loginForm">
            <div className="form-group">
              <label htmlFor="name">Password</label>
              <div className="input-icon">
                <i className="fa fa-lock icon" />
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter your password"
                  minLength={6}
                  required
                />
              </div>
            </div>{' '}
            <div className="form-group">
              <label htmlFor="name">Confirm New Password</label>
              <div className="input-icon">
                <i className="fa fa-lock icon" />
                <input
                  type="password"
                  className="form-control"
                  id="password-confirmation"
                  placeholder="Enter your password"
                  minLength={6}
                  required
                />
              </div>
            </div>{' '}
            <div className="captcha-container form-group" />
            <hr />
            <button
              type="submit"
              id="btn-login"
              className="btn btn-danger btn-block"
            >
              Reset Password
            </button>
          </form>
          <br />
          <div className="captcha-container form-group">
            <Link to="/login">
              <button
                className="btn btn-link reset-pw"
                type="submit"
                onClick={() => {
                  setResetPassword(true);
                }}
              >
                <center>Send another Temporary Password.</center>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
}
export default ResetPasswordPage;
