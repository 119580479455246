import React, { useState, useEffect, useCallback } from 'react';
import { CheckCircle } from 'react-feather';
import Button from 'components/button/button';
import Loader from 'components/loader/loader';
import { useAuth } from 'contexts/auth-context';
import { Redirect } from 'react-router-dom';
import { updateHHSUser, getHHSUserFromAuthID } from 'utils/api';
import './confirmation-modal.scss';

const modalStates = {
  WAITING: 'WAITING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  DONE: 'DONE',
  FAILURE: 'FAILURE',
};

const ConfirmationModalSponsor = () => {
  console.log('my confirmation mode sponsors');
  return (
    <div className="confirmation-modal-sponsor">
      <div>
        {/* <img
          className="confirmation-modal-sponsor-logo"
          src={sponsors.AccountLogo.Small}
          alt={sponsors.AccountName}
        /> */}
      </div>
      <p className="confirmation-modal-sponsor-message">
        These donations are proudly provided by the Hannaford Helps Schools
        program in support of local schools.
      </p>
    </div>
  );
};

export default function ConfirmationModal({
  hidden = true,
  selectedCause,
  closeModal,
}) {
  // Local selected cause to prevent flash during Cancel animation
  const [localSelectedCause, setLocalSelectedCause] = useState(selectedCause);
  console.log('in confirmation modal', window.navigator.userAgent, 'my user');
  useEffect(() => {
    let isCurrent = true;

    if (selectedCause) {
      setLocalSelectedCause(selectedCause);
    } else {
      setTimeout(() => {
        if (isCurrent) {
          setLocalSelectedCause(selectedCause);
        }
      }, 200);
    }

    return () => {
      isCurrent = false;
    };
  }, [selectedCause]);

  const { user, updateUserStats, setUser, setUserID, serial, localSerial } =
    useAuth();
  const [modalState, setModalState] = useState(modalStates.WAITING);

  // const searchVals = qs.parse(window.location.search.slice(1));
  // const userIdForLogin = searchVals.user_uuid || userID || '';
  // console.log("SARCH VALU",searchVals)
  //   console.log("new user info", localSerial, serial)
  const submitDefaultSchool = useCallback(async () => {
    setModalState(modalStates.LOADING);

    const newUser = {
      ...user,
      DefaultSchoolID: selectedCause.CauseID,
      DefaultSchoolName: selectedCause.CauseName,
      Serial: serial,
      UserOS: window.navigator.userAgent,
    };
    // console.log("NEW UESR;", newUser)
    const newUserResponse = await updateHHSUser({ user: newUser });
    if (newUserResponse.Success) {
      console.log(newUserResponse, 'MY RESPONSE');
      window.gtag('event', 'donate', { user });
      updateUserStats();
      setModalState(modalStates.SUCCESS);
      getHHSUserFromAuthID({ AuthID: user.UserID }).then((userResponse) => {
        console.log('USER RESPONSE', userResponse);
        setUser(userResponse);
        setUserID(userResponse.UUID);
        return true;
      });
    }
  }, [user, selectedCause, updateUserStats]);

  const handleDone = () => {
    setModalState(modalStates.LOADING);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`userID:${user.UserID.toString()}`);
    }
    window.location.href = 'https://portal.hannafordhelpsschools.com/';
  };

  const getModalContent = () => {
    switch (modalState) {
      case modalStates.WAITING:
        return (
          <>
            <div className="confirmation-modal-content">
              <h4 className="confirmation-modal-header">Set my school to: </h4>
              {localSelectedCause && (
                <>
                  <h4 className="confirmation-modal-header confirmation-modal-cause">
                    {localSelectedCause.CauseName}
                  </h4>
                  <p className="confirmation-modal-description">
                    {localSelectedCause.CauseLocation}
                  </p>
                </>
              )}
            </div>
            <div className="confirmation-modal-button-section">
              <Button onClick={closeModal}>Cancel</Button>
              <Button onClick={submitDefaultSchool}>OK</Button>
            </div>
          </>
        );

      case modalStates.LOADING:
        return (
          <div className="confirmation-modal-loader-container">
            <Loader />
          </div>
        );

      case modalStates.SUCCESS:
        return (
          <>
            <div className="confirmation-modal-content">
              <h2 className="confirmation-modal-title">Success!</h2>
              <h4 className="confirmation-modal-header">
                You have successfully selected{'        '}
                <span className="confirmation-modal-inline-cause">
                  {'            '}
                  {localSelectedCause?.CauseName}
                  {'   '}
                </span>
                as your school.
              </h4>
            </div>
            <ConfirmationModalSponsor />
            <div className="confirmation-modal-button-section">
              <Button onClick={handleDone}>OK</Button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`confirmation-modal-page ${hidden && 'hidden'}`}>
      <div className="confirmation-modal-window">
        <div className="confirmation-modal-icon-header">
          <CheckCircle color="#fff" size={36} />
        </div>
        {getModalContent()}
      </div>
    </div>
  );
}
