/* eslint-disable no-throw-literal */
/* eslint-disable import/prefer-default-export */
import qs from 'qs';

export async function attemptForgotPassword(
  email,
  origin = window.navigator.userAgent,
  identityProvider = 'user-password'
) {
  console.log(
    JSON.stringify({
      Email: email,
    }),
    'email'
  );
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/HHSForgotPassword`,
    {
      body: JSON.stringify({
        Email: email,
        Origin: origin,
        IdentityProvider: identityProvider,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log(data, 'forgot password data');
    return data.d;
  }

  return { Success: false };
}

export async function attemptResetPassword(
  resetUuid,
  password,
  origin = window.navigator.userAgent,
  identityProvider = 'user-password'
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/HHSResetPassword`,
    {
      body: JSON.stringify({
        ResetUUID: resetUuid,
        NewPassword: password,
        Origin: origin,
        IdentityProvider: identityProvider,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log(data.d, 'login data');
    return data.d;
  }
  return { Success: false };
}

export async function attemptSignup(
  email,
  password,
  name,
  zip,
  origin = window.navigator.userAgent,
  identityProvider = 'user-password'
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/HHSSignup`,
    {
      body: JSON.stringify({
        FirstName: name,
        Email: email,
        Password: password,
        Zipcode: zip,
        Origin: origin,
        IdentityProvider: identityProvider,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );

  // return response
  if (response.ok) {
    const data = await response.json();
    console.log(data, 'signup data');
    console.log(data.d, 'after initaitives');
    return data.d;
  }
  return { Success: false };
}

export async function attemptLogin(
  email,
  password,
  origin = window.navigator.userAgent,
  identityProvider = 'user-password'
) {
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/Login`, {
    body: JSON.stringify({
      Email: email,
      Password: password,
      Origin: origin,
      IdentityProvider: identityProvider,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  if (response.ok) {
    const data = await response.json();
    if (data.d.Success) {
      return data.d;
    }
    return { Success: false, Message: data.d.Message };
  }
  return { Success: false };
}

export async function fetchWithTimeout(url, options, timeout, onTimeout) {
  const timer = new Promise((resolve) => {
    setTimeout(resolve, timeout, {
      timeout: true,
    });
  });
  return Promise.race([fetch(url, options), timer]).then((response) => {
    if (response.timeout) {
      onTimeout();
    }
    return response;
  });
}

export async function getHHSUserFromAuthID({
  AuthID,
  TimezoneOffset = 0,
  Origin = window.navigator.userAgent,
  IdentityProvider = 'user-password',
}) {
  // console.log('getHHSUserFromAuthID', AuthID);
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetHHSUserFromAuthID`,
    {
      body: JSON.stringify({
        AuthID,
        Origin,
        IdentityProvider,
        TimezoneOffset,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, "my response in getuuhs")
  if (response.ok) {
    const data = await response.json();
    if (!data.d.Success) {
      console.warn('ERROR GETTING USER:\n', JSON.stringify(data, null, 2));
    }
    return data.d;
  } 
    console.warn('ERROR GETTING USER:\n', JSON.stringify(response, null, 2));
   return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  };
}

export async function getHHSUserFromGoogleID({
  Email,
  AuthID,
  TimezoneOffset = 0,
  Origin = window.navigator.userAgent,
  IdentityProvider = 'user-password',
}) {
  // console.log('getHHSUserFromAuthID', AuthID);
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetHHSUserFromGoogleID`,
    {
      body: JSON.stringify({
        Email,
        AuthID,
        Origin,
        IdentityProvider,
        TimezoneOffset,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  // console.log(response, "my response in getuuhs")
  if (response.ok) {
    const data = await response.json();
    if (!data.d.Success) {
      console.warn('ERROR GETTING USER:\n', JSON.stringify(data, null, 2));
    }
    return data.d;
  }
  console.warn('ERROR GETTING USER:\n', JSON.stringify(response, null, 2));
  return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  };
}

export async function getCauseList(params) {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_CDDS_API_ENDPOINT
    }/api/Cause/CDDSCauses?${qs.stringify(params)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    10000,
    () => {
      throw new Error('Session Timed out');
    }
  );
  if (!response.ok || response.status !== 200) {
    throw new Error('There was an error processing your session request');
  }

  const data = await response.json();
  // console.log('Get Cause List', data);
  return data;
}

export async function updateHHSUser({
  user,
  Origin = window.navigator.userAgent,
  IdentityProvider = 'user-password',
}) {
  // console.log(user, "my updaes")
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/UpdateHHSUser`,
    {
      body: JSON.stringify({
        user,
        Origin,
        IdentityProvider,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log("update user:", data)
    return data.d;
  }

  return {
    Success: false,
    Message: 'There was an error processing your request, please try again.',
  }
}

export async function getHHSStats({ UserID, TimezoneOffset = 0 }) {
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_ENDPOINT}/GetHHSStats`,
    {
      body: JSON.stringify({ UserID, TimezoneOffset }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    10000,
    () => {
      throw 'Tag submission timed out';
    }
  );
  // console.log('STATS RESPONSE:\n', JSON.stringify(response, null, 2));
  if (!response.ok || response.status !== 200) {
    throw 'There was an error processing your request';
  }

  const data = await response.json();
  // console.log(data, "my data")
  return data.d;
}

export async function getSchoolsByZipCode({
  Serial,
  SessionUUID,
  DateSubmitted,
  UserID,
  AccountID,
  ProgramID,
  DefaultSchool,
  ProductID,
  ProductName,
  ScanValue,
}) {
  const schoolData = [];
  const response = await fetchWithTimeout(
    `${process.env.REACT_APP_API_ENDPOINT}/GetHHSSchools`,
    {
      body: JSON.stringify({
        Serial,
        SessionUUID,
        DateSubmitted,
        UserID,
        AccountID,
        ProgramID,
        DefaultSchool,
        ProductID,
        ProductName,
        ScanValue,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    5000,
    () => {
      throw 'ReVisit submission timed out';
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log('USER TAGS', JSON.stringify(data, null, 2));
    return data.d;
  }
  return schoolData;
}

export const getHHSUserFromUUID = async (UUID) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetHHSUserFromUUID`,
    {
      body: JSON.stringify({
        UUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    // console.log('User Response Data', data);
    return data.d;
  }

  return { Success: false };
};

export const getR2gDonationHistory = async ({ UserUUID }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/GetUserCouponHistory`,
    {
      body: JSON.stringify({
        UserUUID,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    console.log('Donation History', data);
    return data.d;
  }

  return [];
};

export const submitHHSPageView = async ({
  UserID,
  AccountID,
  SponsorID,
  Pathname,
  Ref,
}) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/SubmitHHSageView`,
    {
      body: JSON.stringify({
        PageView: {
          UserID,
          AccountID,
          SponsorID,
          Pathname,
          Ref,
          TimezoneOffset: new Date().getTimezoneOffset(),
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data.d;
  }

  return { Success: false };
};
