import React, { useState, useEffect } from 'react';
import Page from 'components/page/page';
import CauseTable from 'components/cause-table/cause-table';
import { useAuth } from 'contexts/auth-context';
import Panel from 'components/panel/panel';
import ConfirmationModal from 'components/confirmation-modal/confirmation-modal';
import { css } from 'emotion';
import qs from 'qs';
import './redeem-page.scss';

function RedeemPage() {
  const { user } = useAuth();
  console.log('REDEEM PAGE LOADING', user);
  useEffect(() => {
    const searchVals = qs.parse(window.location.search.slice(1));
    // if (!user) {
    //   window.location.href = 'http://localhost:3000';
    // }
    // if (user && !user.ZipCode) {
    //   window.location.href = 'https://hannafordhelpsschools.com';
    // }
  }, [user]);

  const [selectedCause, setSelectedCause] = useState(null);
  useEffect(() => {
    console.log('user id', user.UserID);
    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(`userID:${user.UserID.toString()}`);
    // }
  }, []);

  return (
    <Page className="redeem-page">
      <Panel
        className={css`
          margin-top: 0.5rem;
          @media (max-width: 850px) {
            margin-top: 0.5rem;
          }
        `}
      >
        <h1>Select Your School</h1>
      </Panel>
      <CauseTable
        accountUID={'779F5862-7EEB-4229-BA69-9BF7E42FE249'}
        programUID={'4D5F857B-6101-45EB-8AEA-1C9DEF59DFF5'}
        userLatitude={user?.Latitude || 0}
        userLongitude={user?.Longitude || 0}
        userZipCode={user.ZipCode}
        causeView={'[dbo].[PSIMVW_CDDSHCcause]'}
        setSelectedCause={setSelectedCause}
      />
      <ConfirmationModal
        sponsors={2}
        selectedCause={selectedCause}
        hidden={!selectedCause}
        closeModal={() => setSelectedCause(null)}
        amount={1}
      />
    </Page>
  );
}

export default RedeemPage;
