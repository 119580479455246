/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Page from 'components/page/page';
import WelcomePage from 'pages/welcome-page/welcome-page';
import DonationHistoryPage from 'pages/donation-history-page/donation-history-page';
import { useAuth } from 'contexts/auth-context';
import qs from 'qs';

function HomePage() {
  const { user, prevPage} = useAuth();
  const [urlStringParam, setUrlStringParam] = useState(true);
  const searchVals = qs.parse(window.location.search.slice(1));
  console.log(
    'in home page',
    searchVals,
    'HOME PAGE RENDERING',
    prevPage,
    user
  );
  useEffect(() => {
    if (!user?.UserID) {
      setUrlStringParam(true);
    } else {
      setUrlStringParam(false);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          `userID:${user.UserID.toString()}`
        );
      }
    }
  }, [prevPage, user]);

  return (
    <Page>
      {user?.UserID ? <DonationHistoryPage /> : <WelcomePage />}
      {/* {!inAppView &&<img
              src={HomePageImage}
              width={"500px"}
              alt="Hannaford Helps Schools App"
            />} */}
      {/* <FullScreenLo<WelcomePageader /> */}
      {/* {urlStringParam ? <FullScreenLoader /> : <RedeemPage />} */}
      {/* {inAppView &&  */}
      {/* <DonationHistoryPage/> */}
    </Page>
  );
}

export default HomePage;
