import React from 'react';
import { CheckCircle } from 'react-feather';
import Button from 'components/button/button';
import './no-data-modal.scss';

export default function NoDataModal({
  hidden = true,
  setIsHidden,
  searchTerm,
}) {
  // Local selected cause to prevent flash during Cancel animation

  const handleDone = () => {
    // setModalState(modalStates.DONE)
    // window.location.reload(true)
    setIsHidden(true);
  };

  return (
    <div className={`confirmation-modal-page ${hidden && 'hidden'}`}>
      <div className="confirmation-modal-window">
        <div className="confirmation-modal-icon-header">
          <CheckCircle color="#fff" size={36} />
        </div>
        <div className="confirmation-modal-content">
          <h2 className="confirmation-modal-title">Don&apos;t see your school?</h2>
          {searchTerm === '' ? (
            <div>
              <h4 className="confirmation-modal-header">
                Please make sure your zip code is local to a participating
                school.
              </h4>
              <h4 className="confirmation-modal-header">
                You can also try searching by the name of the school.
              </h4>
            </div>
          ) : (
            <h3 className="confirmation-modal-header">
              Please submit your school for consideration in the 2024 program to{' '}
              <a
                href={'mailto:hannafordhelpsschools@hannaford.com'}
                target="_blank"
                rel="noopener noreferrer"
                className="email-link"
              >
                hannafordhelpsschools@hannaford.com
              </a>
              .
            </h3>
          )}
        </div>
        <div className="confirmation-modal-button-section">
          <Button onClick={handleDone}>OK</Button>
        </div>
      </div>
    </div>
  );
}
