import React, { useEffect, useState } from 'react';
import Panel from 'components/panel/panel';
import { getR2gDonationHistory } from 'utils/api';
import { parseDotNetDate } from 'utils/date';
import { formatMoney } from 'utils/money';
import { useAuth } from 'contexts/auth-context';

import { css } from 'emotion';
import { useTheme } from 'emotion-theming';

const DonationHistoryColumn = ({
  header = false,
  right = false,
  flex = 1,
  children,
}) => (
  <article
    className={css`
      font-weight: ${header ? 'bold' : 'regular'};
      text-align: ${right ? 'right' : 'center'};
      flex: ${flex};
    `}
  >
    {children}
  </article>
);

const DonationHistoryListItem = ({ children }) => {
  const { colors } = useTheme();
  return (
    <li
      className={css`
        border-bottom: 1px solid ${colors.whiteGray};
        display: flex;
        padding: 0.5rem 0;
      `}
    >
      {children}
    </li>
  );
};

const DonationHistoryList = ({ inAppView, children }) => {
  console.log('inAppView, children', inAppView);
  return (
    <ul
      className={css`
        list-style: none;
        padding: 0;
        height: ${inAppView ? '500px' : '100%'};
        overflow-y: scroll;
      `}
    >
      {children}
    </ul>
  );
};

function CouponHistory() {
  const UserUUID = useAuth()?.user?.UUID;
  const [donationHistoryList, setDonationHistoryList] = useState([]);

  useEffect(() => {
    let isCurrent = true;

    if (UserUUID) {
      getR2gDonationHistory({ UserUUID }).then((resp) => {
        console.log('resp', resp);
        if (isCurrent) {
          //   console.log('DONATION HISTORY', resp);
          setDonationHistoryList(resp);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [UserUUID]);

  return (
    <Panel>
      <h1>School Donation History</h1>
      <DonationHistoryList inAppView>
        <DonationHistoryListItem>
          <DonationHistoryColumn header>Date</DonationHistoryColumn>
          <DonationHistoryColumn header center flex={2}>
            School
          </DonationHistoryColumn>
          <DonationHistoryColumn header>Total Donation</DonationHistoryColumn>
        </DonationHistoryListItem>
        {donationHistoryList.map((donation) => (
          <DonationHistoryListItem key={donation.DateRedeemed}>
            <DonationHistoryColumn>
              {parseDotNetDate(donation.dateCreated).toLocaleDateString()}
            </DonationHistoryColumn>
            <DonationHistoryColumn center flex={2}>
              {donation.school}
            </DonationHistoryColumn>
            <DonationHistoryColumn center>
              {formatMoney(donation.totalCoupons)}
            </DonationHistoryColumn>
          </DonationHistoryListItem>
        ))}
      </DonationHistoryList>
    </Panel>
  );
}

export default CouponHistory;
