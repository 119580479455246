import React from 'react';
import Page from 'components/page/page';
import CouponHistory from 'components/donation-history/donation-history';

function DonationHistoryPage() {
  return (
    <Page>
      <CouponHistory />
    </Page>
  );
}

export default DonationHistoryPage;
