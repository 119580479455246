/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth-context';
import qs from 'qs';
import Page from 'components/page/page';
import { attemptLogin, attemptSignup, attemptForgotPassword } from 'utils/api';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';

import './auth-styles.scss';

function Login() {
  const {
    setPrevPage,
    setUser,
    setUserID,
    setLoggedIn,
    resetPassword,
    setResetPassword,
  } = useAuth();
  const [formType, setFormType] = useState('login');

  useEffect(() => {
    const searchVals = qs.parse(window.location.search.slice(1));
    if (
      (searchVals.screen_hint == 'signup' ||
        window.location.pathname.includes('signup')) &&
      !window.location.pathname.includes('login')
    ) {
      setFormType('signup');
      const signupButton = document.getElementById('signupButton');
      signupButton.className += ' selected';
      const loginButton = document.getElementById('loginButton');
      loginButton.className = 'buttonDiv';
    } else {
      setFormType('login');
    }
  }, []);

  const formButtonHandle = (type) => {
    if (type === 'login') {
      setFormType('login');
      const signupButton = document.getElementById('signupButton');
      signupButton.className = 'buttonDiv';
      const loginButton = document.getElementById('loginButton');
      loginButton.className += ' selected';
      document.getElementById('error-message').className =
        'alert alert-danger hide-error';
      document.getElementById('error-message').innerHTML = '';
    } else {
      setFormType('signup');
      const signupButton = document.getElementById('signupButton');
      signupButton.className += ' selected';
      const loginButton = document.getElementById('loginButton');
      loginButton.className = 'buttonDiv';
    }
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    setResetPassword(true);
  };

  const submitResetPassword = async (e) => {
    e.preventDefault();
    const email = document.getElementById('loginForm').elements.email.value;
    console.log('email,', email);
    const resetRes = await attemptForgotPassword(email);
    console.log('resetRes,', resetRes);
    if (resetRes.Success) {
      document.getElementById('error-message').className =
        'alert alert-success show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${resetRes.Message}`;
    } else {
      document.getElementById('error-message').className =
        'alert alert-danger show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${resetRes.Message}`;
    }
    setTimeout(() => {
      window.location.href = 'https://portal.hannafordhelpsschools.com/login';
    }, 1000);
  };

  const submitLogin = async (e) => {
    e.preventDefault();

    const email = document.getElementById('loginForm').elements.email.value;
    const password =
      document.getElementById('loginForm').elements.password.value;
    document.getElementById('error-message').className =
      'alert alert-danger hide-error';

    const userResponse = await attemptLogin(email, password);
    if (userResponse.Success) {
      setUser(userResponse);
      setLoggedIn(true);
      console.log('userResponse', userResponse);
      setUserID(userResponse.UUID);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`userID:${userResponse.UUID}`);
      }
      setPrevPage('authLogin');
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          `userID:${userResponse.UUID.toString()}`
        );
      }
      window.location.href = '/';
    } else {
      // alert('FAILED');
      console.log(userResponse.Message, userResponse, 'error');
      document.getElementById('error-message').className =
        'alert alert-danger show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${userResponse.Message}`;
    }
  };

  const submitSignup = async (e) => {
    e.preventDefault();
    const email = document.getElementById('signupForm').elements.email.value;
    const password =
      document.getElementById('signupForm').elements.password.value;
    const firstName =
      document.getElementById('signupForm').elements.firstName.value;
    const zipCode =
      document.getElementById('signupForm').elements.zipCode.value;
    console.log('test', email, password, firstName, zipCode);
    const userResponse = await attemptSignup(
      email,
      password,
      firstName,
      zipCode
    );
    if (userResponse.Success) {
      console.log('userResponse', userResponse, userResponse.UUID.toString());
      document.getElementById('error-message').className =
        'alert alert-success show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${userResponse.Message}`;
      setUser(userResponse);
      setUserID(userResponse.UUID);
      setPrevPage('authLogin');
      setLoggedIn(true);
      window.location.href = 'https://portal.hannafordhelpsschools.com/redeem';
      //   window.location.href = `http://localhost:3000?user_uuid=${userResponse.UUID}&ref=app/redeem`;
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage(
      //     `userID:${userResponse.UUID.toString()}`
      //   );
      // }
      // window.location.href = 'https://portal.hannafordhelpsschools.com'
    } else {
      console.log(userResponse.Message, userResponse, 'error');
      document.getElementById('error-message').className =
        'alert alert-danger show-error';
      document.getElementById(
        'error-message'
      ).innerHTML = `${userResponse.Message}`;
    }
  };

  // async function onSignIn(googleUser) {
  //   console.log('googleUser', googleUser);
  //   const profile = googleUser.getBasicProfile();
  //   console.log(`ID: ${  profile.getId()}`); // Do not send to your backend! Use an ID token instead.
  //   console.log(`Name: ${  profile.getName()}`);
  //   console.log(`Image URL: ${  profile.getImageUrl()}`);
  //   console.log(`Email: ${  profile.getEmail()}`);
  //   // const googleSignin = await attemptLogin(profile.getEmail(), password);
  //   getHHSUserFromGoogleID({
  //     Email: profile.getEmail(),
  //     AuthID: profile.getId(),
  //     Origin: window.navigator.userAgent,
  //     IdentityProvider: 'google-signin',
  //   }).then((userResponse) => {
  //     console.log('res', userResponse);
  //     setUser(userResponse);
  //     setUserID(userResponse.UUID);
  //     setPrevPage('authLogin');
  //     setLoggedIn(true); // This is null if the 'email' scope is not present.
  //   });
  // }

  return (
    <Page>
      {resetPassword ? (
        <div className="login-container">
          <div className="col-sm-offset-4 login-box">
            <div className="login-header">
              <img src="header-logo1.png" alt="Hannaford" />
              <h3>Hannaford Helps Schools</h3>
            </div>

            <div className="reset-pw-form" id="loginButton">
              Reset Your Password
            </div>
            <hr />
            <div id="error-message" className="alert alert-danger hide-error" />
            <form onSubmit={submitResetPassword} id="loginForm">
              <div className="form-group ">
                <label htmlFor="name">
                  Enter your email to receive a link to reset your password.
                </label>
                <div className="input-icon">
                  <i className="fa fa-envelope icon" />
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your email"
                    // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                  />
                </div>
              </div>{' '}
              <div className="captcha-container form-group" />
              <hr />
              <button
                type="submit"
                id="btn-login"
                className="btn btn-danger btn-block"
              >
                Reset Password
              </button>
            </form>
            <button
              type="submit"
              id="btn-login"
              className="btn btn-danger btn-block"
              onClick={() => {
                setResetPassword(false);
                formButtonHandle('login');
              }}
            >
              back
            </button>
          </div>
        </div>
      ) : (
        <div className="login-container">
          <div className="col-sm-offset-4 login-box">
            <div className="login-header">
              <img src="header-logo1.png" alt="Hannaford" />
              <h3>Hannaford Helps Schools</h3>
            </div>

            <div className="login-signup-selection">
              <div className="buttonDiv selected " id="loginButton">
                <button
                  type="button"
                  className="btn btn-link signup-btn"
                  onClick={() => formButtonHandle('login')}
                >
                  Log In
                </button>
              </div>
              <div className="buttonDiv " id="signupButton">
                <button
                  type="button"
                  className="btn btn-link signup-btn"
                  onClick={() => formButtonHandle('signup')}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div id="error-message" className="alert alert-danger hide-error" />
            {formType === 'login' ? (
              <form onSubmit={submitLogin} id="loginForm">
                <div className="form-group ">
                  <label htmlFor="name">Email</label>
                  <div className="input-icon">
                    <i className="fa fa-envelope icon" />
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      required
                    />
                  </div>
                </div>{' '}
                <div className="form-group">
                  <label htmlFor="name">Password</label>
                  <div className="input-icon">
                    <i className="fa fa-lock icon" />
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>
                <div className="captcha-container form-group" />
                <hr />
                <button
                  type="submit"
                  id="btn-login"
                  className="btn btn-danger btn-block"
                >
                  Log In
                </button>
                <div className="captcha-container form-group">
                  <button
                  type= "submit"
                    className="btn btn-link reset-pw"
                    onClick={forgotPassword}
                  >
                    Don&apos;t remember your password?
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={submitSignup} id="signupForm">
                <div className="form-group">
                  <label htmlFor="name">Email</label>
                  <div className="input-icon">
                    <i className="fa fa-envelope icon" />
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Password</label>
                  <div className="input-icon">
                    <i className="fa fa-lock icon" />
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your password"
                      minLength={6}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Password Confirmation</label>
                  <div className="input-icon">
                    <i className="fa fa-lock icon" />
                    <input
                      type="password"
                      className="form-control"
                      id="passwordConf"
                      placeholder="Confirm your password"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">First Name</label>
                  <div className="input-icon">
                    <i className="fa fa-user icon" />
                    <input
                      type="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Zip Code</label>
                  <div className="input-icon">
                    <i className="fa-solid fa-map-location icon" />
                    <input
                      type="zipCode"
                      className="form-control"
                      id="zipCode"
                      placeholder="Enter your zip code"
                      required
                    />
                  </div>
                </div>
                <div className="captcha-container form-group" />
                <button
                  type="submit"
                  id="btn-signup"
                  className="btn btn-danger btn-block"
                >
                  Sign Up
                </button>
                <hr />
              </form>
            )}
          </div>
        </div>
      )}
    </Page>
  );
}
export default Login;
