import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User } from 'react-feather';

import HeaderLogo from 'images/header-logo.png';
import { useAuth } from 'contexts/auth-context';
import Button from 'components/button/button';

import './header.scss';

function Header() {
  const { isLoggedIn, user, setUser, setLoggedIn, setUserID } = useAuth();

  const location = useLocation();
  const isRedeemPage = location?.pathname.toLowerCase().includes('redeem');

  return (
    <header id="header" className={isRedeemPage ? 'redeem' : ''}>
      <section className="header-content">
        <section className="left-content">
          <Link to="/" className="home-button">
            <img
              src={HeaderLogo}
              alt="Hannaford Helps Schools"
              className="header-logo"
            />
            <div className="header-text">Hannaford Helps Schools</div>
          </Link>
        </section>

        <section className="right-content">
          {isLoggedIn ? (
            <>
              <Link to="/contact-info" className="header-button">
                <User size={28} color="#333" />
                <div className="user-name">{user.FirstName}</div>
              </Link>
              {user.isAdmin && (
                <Button
                  size="small"
                  onClick={() => {
                    setUser('');
                    setLoggedIn(false);
                    setUserID('');
                    localStorage.clear();
                    sessionStorage.clear();
                  }}
                >
                  {' '}
                  <Link to="/welcome-page">Logout</Link>
                </Button>
              )}
            </>
          ) : (
            <Button size="small">
              <Link to="/login">Sign In</Link>
            </Button>
          )}
        </section>
      </section>
    </header>
  );
}

export default Header;
