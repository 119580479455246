import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import 'app.scss';
import Header from 'components/header/header';
import RedeemPage from 'pages/redeem-page/redeem-page';
import HomePage from 'pages/home-page/home-page';
import WelcomePage from 'pages/welcome-page/welcome-page';
import Login from 'components/authentication/login';
import ResetPasswordPage from 'components/authentication/reset-password';
import DonationHistoryPage from 'pages/donation-history-page/donation-history-page';
import useScrollToTop from 'hooks/use-scroll-to-top';
import { useAuth } from 'contexts/auth-context';

function App() {
  useScrollToTop();

  const { user, inAppView } = useAuth();
  const { pathname } = useLocation();

  const UserID = user.id;
  useEffect(() => {
    if (UserID) {
      window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
        page_path: window.location.pathname,
      });
    }
  }, [UserID, pathname]);
  return (
    <div className="App">
      {inAppView || window?.ReactNativeWebView || window?.screen.width < 600 ? (
        <></>
      ) : (
        <Header />
      )}
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/welcome-page">
          <WelcomePage />
        </Route>
        <Route path="/coupon-history">
          <DonationHistoryPage />
        </Route>
        <Route path="/redeem">
          <RedeemPage />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Login />
        </Route>
        <Route path="/forgotPassword">
          <ResetPasswordPage />
        </Route>
        <Route path="*" exact>
          <HomePage />
        </Route>
      </Switch>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
