import React from 'react';
import { Link } from 'react-router-dom';
import Page from 'components/page/page';
import Panel from 'components/panel/panel';
import Button from 'components/button/button';
import { css } from 'emotion';
import IphoneMockupImage from 'images/iphone-mockup.png';
import AppStoreBadge from 'images/app-store-badge.svg';
import GooglePlayBadge from 'images/google-play-badge.svg';

function WelcomePage() {
  return (
    <Page>
      <Panel>
        <h1>Welcome to Hannaford Helps Schools</h1>
        {/* // {loginWithRedirect({ prompt: 'login' })} */}
        <div
          className={css`
            display: flex;
            @media (max-width: 650px) {
              flex-direction: column;
            }
          `}
        >
          <div
            className={css`
              flex: 1;
              padding: 1rem 0;
              @media (max-width: 650px) {
                display: flex;
                justify-content: center;
              }
            `}
          >
            <img
              src={IphoneMockupImage}
              alt="Hannaford Helps Schools App"
              className={css`
                width: 100%;
                height: auto;
                @media (max-width: 650px) {
                  width: auto;
                  height: 15rem;
                }
              `}
            />
          </div>
          <div
            className={css`
              flex: 2;
              margin-left: 2rem;

              @media (max-width: 650px) {
                margin-left: 0;
              }
            `}
          >
            <p>
              The Hannaford Helps Schools Program allows you to give back to a
              local School with every scan of your School Dollars Coupons and
              shelf QR codes in front of participating products.
            </p>
            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              <a href="https://apps.apple.com/us/app/hannaford-helps-schools/id1602983505">
                <img
                  src={AppStoreBadge}
                  alt="Apple App Store"
                  className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.hannafordhelpsschools">
                <img
                  src={GooglePlayBadge}
                  alt="Google Play Store"
                  className={css`
                    height: 3rem;
                    margin: 0 0.5rem;
                    @media (max-width: 450px) {
                      height: 2.5rem;
                    }
                  `}
                />
              </a>
            </div>
            <p>
              If you have already registered, Sign In to your account to direct
              your School Dollars and see your community impact!
            </p>
            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Button size="large">
                <Link to="/login">Sign In</Link>
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </Page>
  );
}

export default WelcomePage;
